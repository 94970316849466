import React from "react";
import { toReadableDate } from "../../utils/toReadableDate";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { useAuth } from "../layout/AuthProvider";

const ConsultInfo = (props) => {
  const { isLoggedIn } = useAuth();
  const consult = props.consult;

  const renderPresentingSymptoms = () => {
    let symptoms = [];
    if (consult.anxietyDisorders) {
      symptoms.push("Anxiety disorders");
    }
    if (consult.depressiveDisordersExcludingPostpartumDepression) {
      symptoms.push("Depressive disorders (excluding postpartum depression)");
    }
    if (consult.postpartumDepression) {
      symptoms.push("Postpartum depression");
    }
    if (consult.bipolarDisorders) {
      symptoms.push("Bipolar and related disorders");
    }
    if (consult.adhd) {
      symptoms.push("Attention-Deficit/Hyperactivity Disorder (ADHD)");
    }
    if (consult.autismSpectrumDisorder) {
      symptoms.push("Autism Spectrum Disorder");
    }
    if (consult.intellectualDevelopmentalDisabilities) {
      symptoms.push(
        "Intellectual/Developmental disability and related behaviors"
      );
    }
    if (consult.disruptiveImpulseControlConductDisorders) {
      symptoms.push("Disruptive, impulse-control, and conduct disorders");
    }
    if (consult.feedingEatingDisorders) {
      symptoms.push("Feeding and eating disorders");
    }
    if (consult.obsessiveCompulsiveAndRelatedDisorders) {
      symptoms.push("Obsessive-compulsive and related disorders");
    }
    if (consult.traumaAndStressorRelatedDisorders) {
      symptoms.push("Trauma and stressor-related disorders");
    }
    if (consult.schizophreniaAndOtherPsychoticDisorders) {
      symptoms.push("Schizophrenia spectrum and other psychotic disorders");
    }
    if (consult.substanceRelatedAlcohol) {
      symptoms.push("Substance-related (alcohol)");
    }
    if (consult.substanceRelatedMarijuana) {
      symptoms.push("Substance-related (marijuana)");
    }
    if (consult.substanceRelatedNicotine) {
      symptoms.push("Substance-related (nicotine)");
    }
    if (consult.substanceRelatedOpioid) {
      symptoms.push("Substance-related (opioid)");
    }
    if (consult.substanceRelatedOthers) {
      symptoms.push("Substance-related (others)");
    }
    if (consult.sleepDisorders) {
      symptoms.push("Sleep Disorders");
    }
    if (consult.suicidalitySelfHarm) {
      symptoms.push("Suicidality or self-harm");
    }
    if (consult.dementiaDelirium) {
      symptoms.push("Dementia/Delirium induced by a medial condition");
    }
    if (consult.neuroDevelopmentalDisorders) {
      symptoms.push(
        "Neurodevelopmental disorders (including developmental delay and intellectual disabilities)"
      );
    }
    if (consult.socialEnvironmentalConcerns) {
      symptoms.push(
        "Social and environmental concerns (including violence, unstable housing, language barriers, isolation/lack of social support, food insecurity, transportation, etc.)"
      );
    }
    if (consult.otherCondition) {
      symptoms.push(consult.otherCondition);
    }
    return symptoms.map((symptom) => <li key={symptom}>{symptom}</li>);
  };

  const renderRequestedServices = () => {
    let services = [];
    if (consult.diagnosticClarificationRequest) {
      services.push("Diagnostic Clarification");
    }
    if (consult.medicationAdjustmentRequest) {
      services.push("Medication Adjustment");
    }
    if (consult.treatmentPlanningRequest) {
      services.push("Treatment Planning");
    }
    if (consult.treatmentResourcesRequest) {
      services.push("Treatment Resources");
    }
    if (consult.crisisInterventionRecommendationsRequest) {
      services.push("Crisis Intervention Recommendations");
    }
    if (consult.otherServiceRequest) {
      services.push(consult.otherServiceRequest);
    }
    return services.map((service) => <li key={service}>{service}</li>);
  };

  const renderStatusBackground = () => {
    switch (consult.status) {
      case "open":
        return "bg-success";
      case "closed":
        return "bg-primary";
      case "cancelled":
        return "bg-secondary";
      default:
        return;
    }
  };

  return (
    <div className="border p-2">
      <h4>Consult Information</h4>
      <p>Consult ID: {consult.id}</p>
      {isLoggedIn === "consumer" ? null : (
        <>
          <p>
            Preferred Communication Method: &nbsp;
            {capitalizeFirstLetter(consult.communicationMethod)}
            {consult.communicationMethod !== "phone" ? null : (
              <> - {consult.callbackTime}</>
            )}
          </p>
        </>
      )}
      <p>
        <span className={renderStatusBackground()}>
          Status: {capitalizeFirstLetter(consult.status)}
        </span>
      </p>
      {consult.reasonForConsult ? (
        <p>
          <b>Reason For Consult: {consult.reasonForConsult}</b>
        </p>
      ) : (
        ""
      )}
      {isLoggedIn === "consumer" ? null : (
        <>
          <p>
            Caller Phone Number: <br />
            {consult.createdByRole.callup_user.phoneNumber}
          </p>
          <p>Requesting Organization: {consult.callup_organization.name}</p>
          <p>
            Organization Phone Number: <br />
            {consult.callup_organization.phoneNumber}
          </p>
          <p>Requested At: {toReadableDate(consult.createdAt)}</p>
          <p>Requested Services:</p>
          <ul>{renderRequestedServices()}</ul>
        </>
      )}
      {consult.closedAt ? (
        <p>Closed At: {toReadableDate(consult.closedAt)}</p>
      ) : (
        ""
      )}
      {consult.fosterCare ? <p>Foster Care: {consult.fosterCare}</p> : null}
      {consult.callup_patient.insurance ? (
        <p>Insurance: {consult.callup_patient.insurance}</p>
      ) : null}
      <p>Presenting Problems:</p>
      <ul>{renderPresentingSymptoms()}</ul>
    </div>
  );
};

export default ConsultInfo;
