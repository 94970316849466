import React from "react";
import { Form } from "react-bootstrap";

const ConsultServicesRequested = (props) => {
  const [diagnosticClarification, setDiagnosticClarification] =
    props.diagnosticClarification || [null, () => {}];
  const [medicationAdjustment, setMedicationAdjustment] =
    props.medicationAdjustment || [null, () => {}];
  const [treatmentPlanning, setTreatmentPlanning] = props.treatmentPlanning || [
    null,
    () => {},
  ];
  const [treatmentResources, setTreatmentResources] =
    props.treatmentResources || [null, () => {}];
  const [
    crisisInterventionRecommendations,
    setCrisisInterventionRecommendations,
  ] = props.crisisInterventionRecommendations || [null, () => {}];
  const [otherServiceProvided, setOtherServiceProvided] =
    props.otherServiceProvided || [null, () => {}];

  return (
    <div>
      <h5>What services do you need?</h5>
      <Form.Group>
        <Form.Check
          type="checkbox"
          label="Diagnostic Clarification"
          name="diagnosticClarification"
          defaultChecked={diagnosticClarification}
          value={diagnosticClarification}
          onChange={(e) => setDiagnosticClarification(e.target.checked)}
        />
        <Form.Check
          type="checkbox"
          label="Medication Adjustment"
          name="medicationAdjustment"
          defaultChecked={medicationAdjustment}
          value={medicationAdjustment}
          onChange={(e) => setMedicationAdjustment(e.target.checked)}
        />
        <Form.Check
          type="checkbox"
          label="Treatment Planning"
          name="treatmentPlanning"
          defaultChecked={treatmentPlanning}
          value={treatmentPlanning}
          onChange={(e) => setTreatmentPlanning(e.target.checked)}
        />
        <Form.Check
          type="checkbox"
          label="Treatment Resources"
          name="treatmentResources"
          defaultChecked={treatmentResources}
          value={treatmentResources}
          onChange={(e) => setTreatmentResources(e.target.checked)}
        />
        <Form.Check
          type="checkbox"
          label="Crisis Intervention Recommendations"
          name="crisisInterventionRecommendations"
          defaultChecked={crisisInterventionRecommendations}
          value={crisisInterventionRecommendations}
          onChange={(e) =>
            setCrisisInterventionRecommendations(e.target.checked)
          }
        />
        <Form.Group controlId="otherServiceProvided" className="mb-3">
          <Form.Label>Other</Form.Label>
          <Form.Control
            type="text"
            placeholder="Please list other services provided here"
            name="otherServiceProvided"
            value={otherServiceProvided}
            onChange={(e) => setOtherServiceProvided(e.target.value)}
          />
        </Form.Group>
      </Form.Group>
    </div>
  );
};

export default ConsultServicesRequested;
