import React, { useState } from "react";
import axios from "axios";
import { Button, Form } from "react-bootstrap";

import { useAuth } from "../layout/AuthProvider";
import ConsultServicesProvided from "./ConsultServicesProvided";
import ConsultTimeSpent from "./ConsultTimeSpent";

const ConsultSummary = (props) => {
  const { isLoggedIn } = useAuth();
  const consult = props.consult;
  const setRefresh = props.setRefresh;
  const setRefreshable = props.setRefreshable;
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(
    consult.callup_note ? consult.callup_note.content : ""
  );
  const isConsultant = useState(isLoggedIn === "consultant")[0];
  const [diagnosticClarification, setDiagnosticClarification] = useState(
    consult.diagnosticClarification || false
  );
  const [medicationAdjustment, setMedicationAdjustment] = useState(
    consult.medicationAdjustment || false
  );
  const [treatmentPlanning, setTreatmentPlanning] = useState(
    consult.treatmentPlanning || false
  );
  const [treatmentResources, setTreatmentResources] = useState(
    consult.treatmentResources || false
  );
  const [
    crisisInterventionRecommendations,
    setCrisisInterventionRecommendations,
  ] = useState(consult.crisisInterventionRecommendations || false);
  const [otherServiceProvided, setOtherServiceProvided] = useState(
    consult.otherServiceProvided || ""
  );
  const [patientCategory, setPatientCategory] = useState(
    consult.patientCategory || ""
  );
  const [otherPatientCategory, setOtherPatientCategory] = useState(
    consult.otherPatientCategory || ""
  );
  const [timeSpent, setTimeSpent] = useState(consult.timeSpent || "");

  const handleEditToggle = () => {
    const currentIsEditing = isEditing;
    setIsEditing(!currentIsEditing);
    setRefreshable(currentIsEditing);
  };

  const handleContentChange = (e) => {
    setEditedContent(e.target.value);
  };

  const saveContent = async () => {
    const noteSaved = await saveNote();
    const servicesSaved = await saveServices();
    const timeSpentSaved = await saveTimeSpent();
    setRefresh((prev) => !prev);
    setIsEditing(false);
    setRefreshable(true);
    if (noteSaved && servicesSaved && timeSpentSaved) {
      alert("Summary saved successfully");
    }
  };

  const saveNote = async () => {
    try {
      const response = await axios.post(`/api/v1/consults/${consult.id}/note`, {
        content: editedContent,
      });
      if (response.status === 200 || response.status === 201) {
        return true;
      } else {
        console.error("Failed to save note");
      }
    } catch (error) {
      console.error("Error saving note:", error);
    }
  };

  const saveServices = async () => {
    if (isConsultant) {
      try {
        const response = await axios.post(
          `/api/v1/consults/${consult.id}/services`,
          {
            diagnosticClarification,
            medicationAdjustment,
            treatmentPlanning,
            treatmentResources,
            crisisInterventionRecommendations,
            otherServiceProvided,
            patientCategory,
            otherPatientCategory,
          }
        );
        if (response.status === 200) {
          return true;
        }
      } catch (error) {
        console.error("Failed to update services provided", error);
        alert("Failed to update services provided");
      }
    } else {
      return null;
    }
  };

  const saveTimeSpent = async () => {
    if (isConsultant) {
      try {
        const response = await axios.post(
          `/api/v1/consults/${consult.id}/timespent`,
          {
            timeSpent,
          }
        );
        if (response.status === 200) {
          return true;
        }
      } catch (error) {
        console.error("Failed to update time spent", error);
        alert("Failed to update time spent");
      }
    }
  };

  return (
    <div className="mb-4">
      <h2>Consult Summary</h2>
      {isEditing ? (
        <Form>
          <Form.Control
            as="textarea"
            rows={10}
            value={editedContent}
            onChange={handleContentChange}
          />
          {isLoggedIn === "consultant" ? (
            <>
              <ConsultServicesProvided
                diagnosticClarification={[
                  diagnosticClarification,
                  setDiagnosticClarification,
                ]}
                medicationAdjustment={[
                  medicationAdjustment,
                  setMedicationAdjustment,
                ]}
                treatmentPlanning={[treatmentPlanning, setTreatmentPlanning]}
                treatmentResources={[treatmentResources, setTreatmentResources]}
                crisisInterventionRecommendations={[
                  crisisInterventionRecommendations,
                  setCrisisInterventionRecommendations,
                ]}
                otherServiceProvided={[
                  otherServiceProvided,
                  setOtherServiceProvided,
                ]}
                patientCategory={[patientCategory, setPatientCategory]}
                otherPatientCategory={[
                  otherPatientCategory,
                  setOtherPatientCategory,
                ]}
              />
              <ConsultTimeSpent timeSpent={[timeSpent, setTimeSpent]} />
            </>
          ) : null}
          <Button className="mt-2" onClick={saveContent}>
            Save
          </Button>
        </Form>
      ) : consult.callup_note ? (
        <>
          <p>{consult.callup_note.content}</p>
          {isConsultant && consult.status === "open" ? (
            <Button onClick={handleEditToggle}>Create/Edit</Button>
          ) : null}
        </>
      ) : (
        <>
          {isConsultant && consult.status === "open" ? (
            <Button onClick={handleEditToggle}>Create/Edit</Button>
          ) : null}
        </>
      )}
    </div>
  );
};

export default ConsultSummary;
