import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Navigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";

import ExistingOrgsDropdown from "../Orgs/ExistingOrgsDropdown";
import PatientRegistration from "../Patients/PatientRegistration";
import NewConsultForm from "./NewConsultForm";
import { useAuth } from "../layout/AuthProvider";
import { pagePrefix } from "../../utils/pagePrefix";

const NewConsultConsumer = (props) => {
  const { isLoggedIn, userStatus } = useAuth();
  const [organizations, setOrganizations] = useState([]);
  const [organizationId, setOrganizationId] = useState(
    String(props.organizationId) || ""
  );
  const [patients, setPatients] = useState([]);
  const [patientId, setPatientId] = useState(String(props.patientId) || "");
  const passedPatient = props.patientId ? true : false;
  const [previousPatient, setPreviousPatient] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [DOB, setDOB] = useState(null);
  const [age, setAge] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [race, setRace] = useState(null);
  const [gender, setGender] = useState(null);
  const [ethnicity, setEthnicity] = useState(null);
  const [insurance, setInsurance] = useState(null);
  const [MRN, setMRN] = useState(null);
  const [reasonForConsult, setReasonForConsult] = useState("");
  const [anxietyDisorders, setAnxietyDisorders] = useState(false);
  const [
    depressiveDisordersExcludingPostpartumDepression,
    setDepressiveDisordersExcludingPostpartumDepression,
  ] = useState(false);
  const [postpartumDepression, setPostpartumDepression] = useState(false);
  const [bipolarDisorders, setBipolarDisorders] = useState(false);
  const [adhd, setAdhd] = useState(false);
  const [autismSpectrumDisorder, setAutismSpectrumDisorder] = useState(false);
  const [
    intellectualDevelopmentalDisabilities,
    setIntellectualDevelopmentalDisabilities,
  ] = useState(false);
  const [
    disruptiveImpulseControlConductDisorders,
    setDisruptiveImpulseControlConductDisorders,
  ] = useState(false);
  const [feedingEatingDisorders, setFeedingEatingDisorders] = useState(false);
  const [
    obsessiveCompulsiveAndRelatedDisorders,
    setObsessiveCompulsiveAndRelatedDisorders,
  ] = useState(false);
  const [
    traumaAndStressorRelatedDisorders,
    setTraumaAndStressorRelatedDisorders,
  ] = useState(false);
  const [
    schizophreniaAndOtherPsychoticDisorders,
    setSchizophreniaAndOtherPsychoticDisorders,
  ] = useState(false);
  const [substanceRelatedAlcohol, setSubstanceRelatedAlcohol] = useState(false);
  const [substanceRelatedMarijuana, setSubstanceRelatedMarijuana] =
    useState(false);
  const [substanceRelatedNicotine, setSubstanceRelatedNicotine] =
    useState(false);
  const [substanceRelatedOpioid, setSubstanceRelatedOpioid] = useState(false);
  const [substanceRelatedOthers, setSubstanceRelatedOthers] = useState(false);
  const [sleepDisorders, setSleepDisorders] = useState(false);
  const [suicidalitySelfHarm, setSuicidalitySelfHarm] = useState(false);
  const [dementiaDelirium, setDementiaDelirium] = useState(false);
  const [neuroDevelopmentalDisorders, setNeuroDevelopmentalDisorders] =
    useState(false);
  const [socialEnvironmentalConcerns, setSocialEnvironmentalConcerns] =
    useState(false);
  const [otherCondition, setOtherCondition] = useState();
  const [communicationMethod, setCommunicationMethod] = useState("");
  const [callbackTime, setCallbackTime] = useState("");
  const [fosterCare, setFosterCare] = useState("");
  const [diagnosticClarificationRequest, setDiagnosticClarificationRequest] =
    useState(false);
  const [medicationAdjustmentRequest, setMedicationAdjustmentRequest] =
    useState(false);
  const [treatmentPlanningRequest, setTreatmentPlanningRequest] =
    useState(false);
  const [treatmentResourcesRequest, setTreatmentResourcesRequest] =
    useState(false);
  const [
    crisisInterventionRecommendationsRequest,
    setCrisisInterventionRecommendationsRequest,
  ] = useState(false);
  const [otherServiceRequest, setOtherServiceRequest] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("/api/v1/users/organizations", { withCredentials: true })
      .then((res) => {
        if (res.status === 200) {
          setOrganizations(res.data);
        }
      })
      .catch((error) => {
        console.error("Failed to get organizations", error);
      });
  }, []);

  useEffect(() => {
    if (!organizationId) return;
    if (userStatus !== "approved") return;
    axios
      .get(`/api/v1/organizations/${organizationId}/patients`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 200) {
          setPatients(res.data.map((d) => d.callup_patient));
          if (props.patientId) {
            setPatientId(props.patientId);
          } else if (res.data.length === 0) {
            setPatientId("0");
          }
        }
      })
      .catch((error) => {
        console.error("Failed to get patients", error);
      });
  }, [organizationId, props.patientId, userStatus]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      organizationId,
      patientId,
      previousPatient,
      firstName,
      lastName,
      DOB,
      age,
      phone,
      email,
      race,
      gender,
      reasonForConsult,
      anxietyDisorders,
      depressiveDisordersExcludingPostpartumDepression,
      postpartumDepression,
      bipolarDisorders,
      adhd,
      autismSpectrumDisorder,
      intellectualDevelopmentalDisabilities,
      disruptiveImpulseControlConductDisorders,
      feedingEatingDisorders,
      obsessiveCompulsiveAndRelatedDisorders,
      traumaAndStressorRelatedDisorders,
      schizophreniaAndOtherPsychoticDisorders,
      substanceRelatedAlcohol,
      substanceRelatedMarijuana,
      substanceRelatedNicotine,
      substanceRelatedOpioid,
      substanceRelatedOthers,
      sleepDisorders,
      suicidalitySelfHarm,
      dementiaDelirium,
      neuroDevelopmentalDisorders,
      socialEnvironmentalConcerns,
      otherCondition,
      communicationMethod,
      callbackTime,
      fosterCare,
      diagnosticClarificationRequest,
      medicationAdjustmentRequest,
      treatmentPlanningRequest,
      treatmentResourcesRequest,
      crisisInterventionRecommendationsRequest,
      otherServiceRequest,
      insurance,
    };
    try {
      const response = await axios.post("/api/v1/consults/consumer", formData);
      if (response.status === 200) {
        navigate(`${pagePrefix}/dashboard`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (isLoggedIn === false) {
    return <Navigate to={`${pagePrefix}/login`} replace />;
  } else if (isLoggedIn) {
    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={8}>
            <h1 className="large text-primary">Request new consult</h1>
            <Form onSubmit={onSubmit}>
              <h4>Organization Info</h4>
              <ExistingOrgsDropdown
                organizations={organizations}
                organizationId={[organizationId, setOrganizationId]}
                passedPatient={passedPatient}
              />
              <PatientRegistration
                patients={patients}
                patientId={[patientId, setPatientId]}
                firstName={[firstName, setFirstName]}
                lastName={[lastName, setLastName]}
                DOB={[DOB, setDOB]}
                age={[age, setAge]}
                phone={[phone, setPhone]}
                email={[email, setEmail]}
                race={[race, setRace]}
                gender={[gender, setGender]}
                ethnicity={[ethnicity, setEthnicity]}
                insurance={[insurance, setInsurance]}
                MRN={[MRN, setMRN]}
                passedPatient={passedPatient}
                previousPatient={[previousPatient, setPreviousPatient]}
              />
              <NewConsultForm
                reasonForConsult={[reasonForConsult, setReasonForConsult]}
                anxietyDisorders={[anxietyDisorders, setAnxietyDisorders]}
                depressiveDisordersExcludingPostpartumDepression={[
                  depressiveDisordersExcludingPostpartumDepression,
                  setDepressiveDisordersExcludingPostpartumDepression,
                ]}
                postpartumDepression={[
                  postpartumDepression,
                  setPostpartumDepression,
                ]}
                bipolarDisorders={[bipolarDisorders, setBipolarDisorders]}
                adhd={[adhd, setAdhd]}
                autismSpectrumDisorder={[
                  autismSpectrumDisorder,
                  setAutismSpectrumDisorder,
                ]}
                intellectualDevelopmentalDisabilities={[
                  intellectualDevelopmentalDisabilities,
                  setIntellectualDevelopmentalDisabilities,
                ]}
                disruptiveImpulseControlConductDisorders={[
                  disruptiveImpulseControlConductDisorders,
                  setDisruptiveImpulseControlConductDisorders,
                ]}
                feedingEatingDisorders={[
                  feedingEatingDisorders,
                  setFeedingEatingDisorders,
                ]}
                obsessiveCompulsiveAndRelatedDisorders={[
                  obsessiveCompulsiveAndRelatedDisorders,
                  setObsessiveCompulsiveAndRelatedDisorders,
                ]}
                traumaAndStressorRelatedDisorders={[
                  traumaAndStressorRelatedDisorders,
                  setTraumaAndStressorRelatedDisorders,
                ]}
                schizophreniaAndOtherPsychoticDisorders={[
                  schizophreniaAndOtherPsychoticDisorders,
                  setSchizophreniaAndOtherPsychoticDisorders,
                ]}
                substanceRelatedAlcohol={[
                  substanceRelatedAlcohol,
                  setSubstanceRelatedAlcohol,
                ]}
                substanceRelatedMarijuana={[
                  substanceRelatedMarijuana,
                  setSubstanceRelatedMarijuana,
                ]}
                substanceRelatedNicotine={[
                  substanceRelatedNicotine,
                  setSubstanceRelatedNicotine,
                ]}
                substanceRelatedOpioid={[
                  substanceRelatedOpioid,
                  setSubstanceRelatedOpioid,
                ]}
                substanceRelatedOthers={[
                  substanceRelatedOthers,
                  setSubstanceRelatedOthers,
                ]}
                sleepDisorders={[sleepDisorders, setSleepDisorders]}
                suicidalitySelfHarm={[
                  suicidalitySelfHarm,
                  setSuicidalitySelfHarm,
                ]}
                dementiaDelirium={[dementiaDelirium, setDementiaDelirium]}
                neuroDevelopmentalDisorders={[
                  neuroDevelopmentalDisorders,
                  setNeuroDevelopmentalDisorders,
                ]}
                socialEnvironmentalConcerns={[
                  socialEnvironmentalConcerns,
                  setSocialEnvironmentalConcerns,
                ]}
                otherCondition={[otherCondition, setOtherCondition]}
                communicationMethod={[
                  communicationMethod,
                  setCommunicationMethod,
                ]}
                callbackTime={[callbackTime, setCallbackTime]}
                fosterCare={[fosterCare, setFosterCare]}
                diagnosticClarificationRequest={[
                  diagnosticClarificationRequest,
                  setDiagnosticClarificationRequest,
                ]}
                medicationAdjustmentRequest={[
                  medicationAdjustmentRequest,
                  setMedicationAdjustmentRequest,
                ]}
                treatmentPlanningRequest={[
                  treatmentPlanningRequest,
                  setTreatmentPlanningRequest,
                ]}
                treatmentResourcesRequest={[
                  treatmentResourcesRequest,
                  setTreatmentResourcesRequest,
                ]}
                crisisInterventionRecommendationsRequest={[
                  crisisInterventionRecommendationsRequest,
                  setCrisisInterventionRecommendationsRequest,
                ]}
                otherServiceRequest={[
                  otherServiceRequest,
                  setOtherServiceRequest,
                ]}
              />
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <Container className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }
};

export default NewConsultConsumer;
