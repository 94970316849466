// export const toReadableDate = (dateString) => {
//   const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2})/;

//   const match = dateString.match(regex);

//   if (match) {
//     const year = match[1];
//     const month = match[2];
//     const date = match[3];
//     const hours = match[4];
//     const minutes = match[5];
//     const localTime = toLocalTime(hours, minutes);
//     return `${month}/${date}/${year} at ${localTime}`;
//   } else {
//     return "Invalid date";
//   }
// };

// const toLocalDate = (date) => {
//   const month = date.getUTCMonth() + 1;
//   const day = date.getUTCDate();
//   const year = date.getUTCFullYear();
//   return `${month}/${day}/${year}`;
// };

// const toLocalTime = (hours, minutes) => {
//   const ampm = hours >= 12 ? "PM" : "AM";
//   if (hours === 0) {
//     hours = 12; // midnight
//   } else if (hours > 12) {
//     hours -= 12; // PM hours
//   }
//   return `${hours}:${minutes} ${ampm}`;
// };

export const toReadableDate = (dateString) => {
  const date = new Date(dateString);
  let localTime = date.toLocaleTimeString();
  localTime = localTime.replace(/:\d+ /, " ");
  return `${date.toLocaleDateString()} at ${localTime}`;
};
