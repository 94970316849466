import React, { useEffect, useState } from "react";
import axios from "axios";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Container, Form, Modal, Table } from "react-bootstrap";
import { useAuth } from "../../layout/AuthProvider";

import UserCredentials from "../../utils/UserCredentials";
import { pagePrefix } from "../../../utils/pagePrefix";

const UsersAdmin = () => {
  const { isLoggedIn } = useAuth();
  const [users, setUsers] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [subtype, setSubtype] = useState("");
  const [credential, setCredential] = useState("");
  const [status, setStatus] = useState("");
  const [nameFilter, setNameFilter] = useState("");

  useEffect(() => {
    axios
      .get("/api/v1/users/admin")
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.error(err);
        alert("Failed to get users");
      });
  }, [refresh]);

  const filterUsers = (us) => {
    if (!nameFilter) {
      return us;
    }
    return us.filter((user) => {
      let name =
        user.firstName.toLowerCase() + " " + user.lastName.toLowerCase();
      return name.includes(nameFilter.toLowerCase());
    });
  };

  const renderFilters = () => {
    return (
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Filter by name</Form.Label>
          <Form.Control
            type="text"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          />
        </Form.Group>
      </Form>
    );
  };

  const renderUsersTable = () => {
    if (users.length === 0) {
      return (
        <tr>
          <td colSpan="5">No users found</td>
        </tr>
      );
    }
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Type</th>
            <th>Status</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {filterUsers(users).map((user) => (
            <tr key={user.id}>
              <td>
                {user.firstName} {user.lastName}
              </td>
              <td>{user.email}</td>
              <td>{user.type}</td>
              <td>{user.status}</td>
              <td>
                <Button onClick={() => editUser(user)}>Edit</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const handleModalClose = () => {
    setShowModal(false);
    setUserId(null);
    setFirstName("");
    setLastName("");
    setEmail("");
    setType("");
    setSubtype("");
    setCredential("");
    setStatus("");
  };

  const editUser = (user) => {
    setShowModal(true);
    setUserId(user.id);
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
    setType(user.type);
    setSubtype(user.subtype);
    setCredential(user.credential);
    setStatus(user.status);
  };

  const onSubmit = () => {
    if (!firstName || !lastName || !email || !type || !status || !credential) {
      alert("Please fill out all fields");
      return;
    }
    axios
      .put(`/api/v1/users/${userId}`, {
        firstName,
        lastName,
        email,
        type,
        subtype,
        credential,
        status,
      })
      .then((res) => {
        alert("User updated");
        setRefresh(!refresh);
        handleModalClose();
      })
      .catch((err) => {
        console.error(err);
        alert("Failed to update user");
      });
  };

  const renderUserModal = () => {
    return (
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Type</Form.Label>
              <Form.Select
                name="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
                required
              >
                <option value="consumer">Consumer</option>
                <option value="admin">Admin</option>
                <option value="consultant">Consultant</option>
                <option value="call_center">Call Center</option>
              </Form.Select>
            </Form.Group>
            {type === "consultant" ? (
              <Form.Group className="mb-3">
                <Form.Label>Subtype</Form.Label>
                <Form.Select
                  name="subtype"
                  value={subtype}
                  onChange={(e) => setSubtype(e.target.value)}
                >
                  <option value="">
                    Please select a subtype for this consultant
                  </option>
                  <option value="psychiatrist">Psychiatrist</option>
                  <option value="care coordinator">Care Coordinator</option>
                </Form.Select>
              </Form.Group>
            ) : null}
            <Form.Label>Credentials</Form.Label>
            <UserCredentials credential={[credential, setCredential]} />
            <Form.Group className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Select
                name="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                required
              >
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="declined">Declined</option>
                <option value="deactivated">Deactivated</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  if (isLoggedIn === null) {
    return <p>Loading</p>;
  } else if (isLoggedIn === "admin") {
    return (
      <Container>
        <h1>Users</h1>
        <LinkContainer to={`${pagePrefix}/admin/register/user`}>
          <Button variant="primary" size="sm">
            Register New User
          </Button>
        </LinkContainer>
        {renderFilters()}
        {renderUsersTable()}
        {renderUserModal()}
      </Container>
    );
  } else {
    return <p>Unauthorized</p>;
  }
};

export default UsersAdmin;
