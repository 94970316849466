import React from "react";
import { Form } from "react-bootstrap";

import { useAuth } from "../layout/AuthProvider";
import { Asterisk } from "../utils/Asterisk";

const ConsultServicesProvided = (props) => {
  const { isLoggedIn } = useAuth();
  const [diagnosticClarification, setDiagnosticClarification] =
    props.diagnosticClarification || [null, () => {}];
  const [medicationAdjustment, setMedicationAdjustment] =
    props.medicationAdjustment || [null, () => {}];
  const [treatmentPlanning, setTreatmentPlanning] = props.treatmentPlanning || [
    null,
    () => {},
  ];
  const [treatmentResources, setTreatmentResources] =
    props.treatmentResources || [null, () => {}];
  const [
    crisisInterventionRecommendations,
    setCrisisInterventionRecommendations,
  ] = props.crisisInterventionRecommendations || [null, () => {}];
  const [otherServiceProvided, setOtherServiceProvided] =
    props.otherServiceProvided || [null, () => {}];
  const [patientCategory, setPatientCategory] = props.patientCategory || [
    null,
    () => {},
  ];
  const [otherPatientCategory, setOtherPatientCategory] =
    props.otherPatientCategory || [null, () => {}];

  const isDisabled = () => {
    if (isLoggedIn !== "consultant") return true;
    return false;
  };

  return (
    <div>
      <h5>
        Services Provided: <Asterisk />
      </h5>
      <Form.Group>
        <Form.Check
          type="checkbox"
          label="Diagnostic Clarification"
          name="diagnosticClarification"
          defaultChecked={diagnosticClarification}
          value={diagnosticClarification}
          onChange={(e) => setDiagnosticClarification(e.target.checked)}
          disabled={isDisabled()}
        />
        <Form.Check
          type="checkbox"
          label="Medication Adjustment"
          name="medicationAdjustment"
          defaultChecked={medicationAdjustment}
          value={medicationAdjustment}
          onChange={(e) => setMedicationAdjustment(e.target.checked)}
          disabled={isDisabled()}
        />
        <Form.Check
          type="checkbox"
          label="Treatment Planning"
          name="treatmentPlanning"
          defaultChecked={treatmentPlanning}
          value={treatmentPlanning}
          onChange={(e) => setTreatmentPlanning(e.target.checked)}
          disabled={isDisabled()}
        />
        <Form.Check
          type="checkbox"
          label="Treatment Resources"
          name="treatmentResources"
          defaultChecked={treatmentResources}
          value={treatmentResources}
          onChange={(e) => setTreatmentResources(e.target.checked)}
          disabled={isDisabled()}
        />
        <Form.Check
          type="checkbox"
          label="Crisis Intervention Recommendations"
          name="crisisInterventionRecommendations"
          defaultChecked={crisisInterventionRecommendations}
          value={crisisInterventionRecommendations}
          onChange={(e) =>
            setCrisisInterventionRecommendations(e.target.checked)
          }
          disabled={isDisabled()}
        />
        <Form.Group controlId="otherServiceProvided" className="mb-3">
          <Form.Label>Other</Form.Label>
          <Form.Control
            type="text"
            placeholder="Please list other services provided here"
            name="otherServiceProvided"
            value={otherServiceProvided}
            onChange={(e) => setOtherServiceProvided(e.target.value)}
            disabled={isDisabled()}
          />
        </Form.Group>
      </Form.Group>
      <h5>
        Patient Category: <Asterisk />
      </h5>
      <Form.Group className="mb-3">
        <Form.Select
          name="patientCategory"
          value={patientCategory}
          onChange={(e) => setPatientCategory(e.target.value)}
          disabled={isDisabled()}
          required
        >
          <option value="">Select one</option>
          <option value="Infants (age <1 year)">
            Infants (age &lt;1 year)
          </option>
          <option value="Children, Adolescents, and Young Adults (age 1-25)">
            Children, Adolescents, and Young Adults (age 1-25)
          </option>
          <option value="Children and Adolescents (age 1-17)">
            Children and Adolescents (age 1-17)
          </option>
          <option value="Young Adults (age 18-25)">
            Young Adults (age 18-25)
          </option>
          <option value="Special Health Care Needs (age 0-25)">
            Special Health Care Needs (age 0-25)
          </option>
          <option value="Pregnant/postpartum persons (all ages)">
            Pregnant/postpartum persons (all ages)
          </option>
          <option value="Men (age 26+)">Men (age 26+)</option>
          <option value="Families">Families</option>
          <option value="Others (specify)">Others (specify)</option>
          <option value="Unknown">Unknown</option>
        </Form.Select>
        {patientCategory === "Others (specify)" && (
          <Form.Group controlId="otherPatientCategory" className="mb-3">
            <Form.Label>Other</Form.Label>
            <Form.Control
              type="text"
              placeholder="Please specify other patient category"
              name="otherPatientCategory"
              value={otherPatientCategory}
              onChange={(e) => setOtherPatientCategory(e.target.value)}
              disabled={isDisabled()}
            />
          </Form.Group>
        )}
      </Form.Group>
    </div>
  );
};

export default ConsultServicesProvided;
